<template lang="pug">
div( v-if="role !== ''")
  //CButton(@click="createOrder(1)", color="info" size="sm" class="m-2", v-if="role==='MainUser'") Создать заявку из Китая
  //CButton(@click="createOrder(2)", color="info" size="sm" class="m-2", v-if="role==='MainUser'") Создать заявку из России
  div(v-if="role !== ''")
    vuecrud(host="http://wild", selectedtable = "client_orders", :columnsupdated="columnsupdated", :opts="opts", style="margin-top:15px")
      template(v-slot:popupHeader="binds")
        div.datatable-modal-container(style="background-color:#3c4b64; color: white; padding: 16px")
          h5.modal-title ДОБАВЛЕНИЕ ЗАКАЗА
      template(v-slot:row="binds")
        tr.goodsTable(@click="$router.push('/orders/' + binds.row.id)")
          td {{binds.row.id}}
          //td {{binds.row.user}}
          td {{binds.row.name}}
          td {{binds.row.desc_client}}
          td {{getStatus(binds.row.status)}}
          td {{binds.row.status_updated}}
          td {{binds.row.type === 1 ? "Китай" : "Россия"}}
          td {{getCurrency(binds.row.currency)}}
          td {{binds.row.course}}
          td {{binds.row.course_usd}}
          td
            div(v-if="role==='Admin' || role==='Manager'")
              CButton(@click.stop.prevent="removethis(binds.row)", class="btn-danger btn-xs") Удалить

</template>

<script>
export default {
  name: "Partner_Orders_CRM",
  data: function () {
    return {
      show: true,
      isCollapsed: true,

      opts: {
        canAdd: true,
        canEdit: true,
        canDelete: true,
        where: this.getWhere()
      },
      users: []
    }
  },
  methods: {

    columnsupdated: function (cols) {

      cols.set('desc_client', {label: "Описание", hasFilter: true, isShowOnPopup: true, isEdit: true });
      cols.set('name', {label: "Партнер", hasFilter: true, type: 'fixed', isShowOnPopup: false, isEdit: false });

      cols.delete('desc_manager', {label: "Название", hasFilter: false, isShowOnPopup: false, isEdit: false });
      //cols.delete('user', {label: "ID партнера", hasFilter: false });
      cols.delete('user', {
        label: "Партнер",
        hasFilter: false,
        type: 'text',
        isShowOnPopup: (this.$store.state.User.role != 'MainUser') ? true : false,
        isEdit: (this.$store.state.User.role != 'MainUser') ? true : false,
        isLoadKeys: true,
        selectRepresentAs: (column) => '# ' + column.id + ' ' + column.name
      });

      cols.set('id', {label: "Номер заявки", hasFilter: true, type: 'fixed', isShowOnPopup: false, isEdit: false });
      cols.set('status', {label: "Статус", hasFilter: true, type: 'fixed', isShowOnPopup: false, isEdit: false });
      cols.set('status_updated', {label: "Статус обновлён", hasFilter: true, type: 'fixed', isShowOnPopup: false, isEdit: false });

      cols.set('type', {
        label: "Страна",
        editName: "Страна",
        hasFilter: false,
        isShowOnPopup: true,
        isEdit: true,
        isLoadKeys: true,
        selectRepresentAs: (column) => column.name,
        representedAs: (row) => {
          switch (row.type) {
            case 1:
              return 'Китай';
            /*case 2:
              return 'Россия';*/
          }
        },
      });
      cols.set('currency', {
        label: "Влаюта",
        editName: "Влаюта",
        hasFilter: false,
        isShowOnPopup: true,
        isEdit: true,
        isLoadKeys: true,
        selectRepresentAs: (column) => column.name,
        representedAs: (row) => {
          switch (row.currency) {
            case 1:
              return 'RUB';
            case 2:
              return 'USD';
            case 3:
              return 'UNY';
          }
        },
      });
      cols.set('course', {label: "Курс Юаня", editName: "Курс Юаня", hasFilter: false, type: 'fixed', isShowOnPopup: true, isEdit: true });
      cols.set('course_usd', {label: "Курс Доллара", editName: "Курс Доллара", hasFilter: false, type: 'fixed', isShowOnPopup: true, isEdit: true });

    },

    createOrder: function (type) {

      // Сперва создаём новую заяву в базе, потом сразу переносим по номеру заявки
      let name = prompt("Название заявки", "Новая заявка");
      window.REST.create('client_orders', {desc_client: name, status: type === 1 ? 1 : 16, type: type}).then( res=>this.$router.push('orders/'+res.data.id) )

    },

    //getRole: function () {return this.$route.meta.role},

    getWhere: function() {

      switch (this.$store.state.User.role) {
        case 'Admin': return {};
        case 'Manager': return {status: ['IN', 'client_orders.status',[2, 3, 4,  17, 18, 19]]};
        case 'BuyManager': return {status: ['IN', 'client_orders.status',[5, 6, 7, 8,  20, 21, 22, 23, 24]]};
        case 'Fullfill': return {status: ['IN', 'client_orders.status',[9, 10, 11, 12, 13, 14,  25, 26, 27, 28, 29]]};
          //case 'RegDir': return {status: ['IN', 'client_orders.status',[2, 3, 4,  17, 18, 19]]};
        case 'RegDir': return {};
        case 'MainUser': return {user: this.$store.state.User.id};
        default: return {};
      }

    },

    getStatus: function (id) {
      return this.$store.state.statuses.find(res=>res.id===id)?.name;
    },

    getCurrency: function (currency) {
      switch (currency) {
        case 1:
          return 'RUB';
        case 2:
          return 'USD';
        case 3:
          return 'UNY';
      }
    },

    removethis: function (row) {
      if(window.confirm("Удалить заявку #"+row.id + "("+row.desc_client+")?")) {
        window.REST.remove('client_orders', row.id ).then(r=>{
          window.location.reload();
        });
      }
    }

  },

  computed: {
    role: function () {
      return this.$store.state.User.role
    },
  },

  mounted() {
    let that = this;
    setTimeout(()=>{
      if(that.role === 'RegDir')
        window.REST.get('partners', {regdir: that.$store.state.User.id}).then(res=>{
          let partlist = res.data.map(partner => partner.id);
          return window.REST.get('user', {partner: ['IN', 'partner', partlist]});
        }).then(res=>{
          that.$set(that, 'users', res.data.map(r=>r.id));
        })
    }, 500);
  }


}
</script>

<style scoped lang="SCSS">
</style>
