import { render, staticRenderFns } from "./Partner_Orders_CRM.vue?vue&type=template&id=4b1415e9&scoped=true&lang=pug&"
import script from "./Partner_Orders_CRM.vue?vue&type=script&lang=js&"
export * from "./Partner_Orders_CRM.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b1415e9",
  null
  
)

export default component.exports